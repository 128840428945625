export const baseUrl =

  //"https://emr-api.healthconnectpro.in/api/clinicapi/Appointments/";

  "https://testemr-api-php.cb-dev.in/api/clinicapi/Appointments/";

//global.baseUrl = 'https://emr-api.healthconnectpro.in';

global.baseUrl = "https://testemr-api-php.cb-dev.in"

export async function networkCall(method, endPoint, body) {
  try {
    const response = await fetch(baseUrl + endPoint, {
      method: method,
      body: JSON.stringify(body),
    });
    return response.json();
  } catch (e) {
    return { status: 0, message: "" + e };
  }
}
